<template>
  <component :is="commonComponent" id="blog" title="Blogs">
    <section id="blog-content" class="pc_padding">
      <h1 class="m-b-30 head-1">
        How to Properly Plan Your Day?
      </h1>

      <div class="autor m-b-30">
        <div>Author: Jake GlobeTrotter </div>
        <div>Publish: 2024/8/25 </div>
      </div>
      <div class="slish m-b-20" />

      <div class="ad-width-100 ad-blog-row m-b-30">
        <AdComponent ref="ads-blog-3-1" :ads="adsensConfig.blog_1_1" :showDebug="showDebug" />
      </div>

      <p>
        In our fast-paced lives, time is like gold, with every second being incredibly precious. We
        all have 24 hours, but why do some people manage to accomplish more while others always feel
        short on time? <strong>The answer lies in time management.</strong>
      </p>

      <p>
        Time management is a skill that helps us use our time more effectively, enabling us to
        achieve our goals and dreams. It not only improves our work efficiency but also helps us
        find more time for rest and leisure, enhancing our quality of life. However, many people do
        not know how to manage their time properly, which is the topic I want to explore today.
      </p>

      <p>
        We all know that time is our most valuable resource. Therefore, we need to learn to cherish
        and use it wisely to achieve our goals and dreams. Properly planning your day is the first
        step towards this goal. So, how can we plan our day effectively?
      </p>

      <div class="ad-width-100 ad-blog-row m-b-30">
        <AdComponent ref="ads-blog-3-2" :ads="adsensConfig.blog_1_2" :showDebug="showDebug" />
      </div>

      <h2 class="head-2 m-t-60 m-b-30">
        Identify Your Peak Productivity Periods
      </h2>

      <p class="m-t-30 m-b-30">
        <img class="border-radius-20" alt="img" src="@/assets/most_productive.png">
      </p>

      <p>
        To plan your day well, you first need to understand yourself. Everyone has their biological
        clock; some are most energetic in the morning, while others are more creative at night.
        Identifying your peak productivity periods can help you better schedule tasks and improve
        work efficiency.
      </p>

      <p>
        If you&#39;re unsure about your peak times, start by tracking your activities for a week
        and review when your work efficiency is highest. For example, if you find that you&#39;re
        most alert and productive in the few hours after waking up, then this is your peak time.
        Schedule difficult and important tasks during this period to complete them in your best
        state.
      </p>

      <h2 class="head-2 m-t-60 m-b-30">
        Find the Three Most Important Tasks
      </h2>

      <p class="m-t-30 m-b-30">
        <img class="border-radius-20" alt="img"
          src="@/assets/8630e74e-81ca-4eb0-95d7-feb9a19b7a73_1200x630.jpg">
      </p>

      <p>
        A day has only 24 hours, and after accounting for eating and sleeping, we have about half
        of that time left for productive activities. Therefore, we can&#39;t complete as many tasks
        in a day as we might think. I used to fall into the trap of overloading my schedule, trying
        to accomplish a week&#39;s worth of tasks in a day, but the more I planned, the less I
        completed.
      </p>

      <p>
        <strong>The key is not the number of tasks but the effectiveness of completing the most
          important ones</strong>. Identify the three most important tasks for the day. These should
        be tasks that significantly contribute to your goals. Completing these three tasks makes
        your day valuable without worrying about numerous trivial matters.
      </p>

      <h2 class="head-2 m-t-60 m-b-30">
        Maintain Healthy Habits
      </h2>

      <p class="m-t-30 m-b-30">
        <img class="border-radius-20" alt="img" src="@/assets/INFOGRAFIA-En-2.jpg">
      </p>

      <p>
        To effectively plan your day, you can&#39;t ignore healthy habits, which are the foundation
        of effective time management. Regular sleep, balanced diet, and moderate exercise are
        crucial. Adequate sleep is essential for restoring energy, and a well-nourished body and
        mind are prerequisites for high efficiency.
      </p>

      <p>
        A regular diet provides the necessary energy and nutrients, helping you maintain a good
        physical state. Moderate exercise improves your physical condition, energy, and
        concentration, aiding in high-efficiency work. Establishing healthy habits may require time
        and effort, but the rewards are significant.
      </p>

      <h2 class="head-2 m-t-60 m-b-30">
        Conclusion
      </h2>

      <p>
        Properly planning your day involves understanding the underlying methods of time
        management, identifying your peak productivity periods, focusing on the most important
        tasks, and maintaining healthy habits. This approach will help you manage your time more
        effectively.
      </p>
    </section>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
// 按需动态引入 PC 和 Mobile 版本的 CommonPage
const PcCommon = () => import('@/pages/components/pc_common.vue');
const MobileCommon = () => import('@/pages/components/m_common.vue');
import AdComponent from '@/pages/components/AdComponent.vue'

import '@/css/common.scss';
import '@/css/blog_base.scss';

export default {
  name: 'Blog3',
  components: {
    AdComponent
  },
  mixins: [common],
  data () {
    return {
      commonComponent: null,
    };
  },
  metaInfo () {
    return {
      title: 'How to Properly Plan Your Day?',
      meta: [
        {
          name: 'description',
          content: 'This article discusses how to plan your day effectively through time management. It covers identifying peak productivity periods, focusing on the three most important tasks, and maintaining healthy habits to maximize efficiency and achieve personal goals.',
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
      "adsensConfig",

    ])
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
    await this.loadAdSenseScript();  // 重新加载广告脚本
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
    await this.loadAdSenseScript();  // 重新加载广告脚本
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
  methods: {
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        this.$eventrack('adscript_loaded', 'expose');
        this.loadAdWithDelay()
        return; // 如果已加载，直接返回
      }
      await this.loadScriptConditionally();
    },
    loadScriptConditionally () {
      console.log(this.adsensConfig);
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        this.$eventrack('no_adscript_config', 'expose');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig.scriptUrl}"]`);
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose');
        console.log('脚本已存在，无需重新添加');
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsensConfig?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      this.$eventrack('adscript_add_success', 'expose');
      script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');

    },

    loadAdWithDelay () {
      setTimeout(() => {
        this.displayAd();
      }, 1500);
    },
    async displayAd () {
      await this.$nextTick();

      // 获取所有 ads 元素的 refs，过滤掉非广告的 ref
      const adsElements = Object.entries(this.$refs)
        .filter(([key]) => key.startsWith('ads-')) // 只选择以 'ads-' 开头的 refs
        .flatMap(([, ref]) => ref); // 展开并获取所有元素

      // .filter(ref => ref instanceof HTMLDivElement || ref instanceof HTMLIFrameElement); // 过滤出 DOM 元素

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log("Adsense script not loaded yet, delaying ad display.");
        setTimeout(this.displayAd, 500); // 延迟再次尝试
        return;
      }

      console.log(adsElements); // 检查是否包含 <ins> 标签
      adsElements.forEach(ad => {
        console.log(ad); // 输出每个广告元素
        console.log('ready to push');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      });
    },
  },
};
</script>
